/* You can add global styles to this file, and also import other style files */
:root {
	--total-red: #f00;
	--total-blue: #285aff;
	--total-light-blue: #009bff;
	--total-turquoise: #32c8c8;
	--total-green: #28c896;
	--total-light-green: #96e600;
	--total-orange: #ffc800;
	--total-grey: #374649;
	--total-outline-btn-red-hover: #fcd7d7;
	--total-outline-btn-orange-hover: #ffecbf;
	--total-outline-btn-green-hover: #ceffbf;
	--total-red-gradient: #f00, #ff6e00;
	--total-blue-gradient: #4632ff, #0af;
	--total-light-blue-gradient: #0082ff, #00c8ff;
	--total-turquoise-gradient: #0af, #00c8aa;
	--total-green-gradient: #00c8af, #78d700;
	--total-light-green-gradient: #3cd700, #ffd700;
	--total-orange-gradient: #ffdc00, #ffaf00;
	--total-rainbow: var(--total-red), var(--total-blue), var(--total-light-blue), var(--total-turquoise),
		var(--total-green), var(--total-light-green), var(--total-orange);
	--total-gradient-green-blue: var(--total-blue), var(--total-green);
	--total-gradient-red-orange: var(--total-red), var(--total-orange);
	--total-gradient-orange-red: var(--total-orange), var(--total-red);
	--b2b-main-color: var(--total-light-blue);
	--b2b-main-color-gradient: var(--total-light-blue-gradient);
	--b2b-box-color-gradient: var(--total-light-blue-gradient);
	--b2b-title-color-gradient: var(--total-light-blue-gradient);
	--b2b-timeline-icon-color: var(--total-green);
	--b2b-timeline-title-color-gradient: var(--total-green-gradient);
	--b2c-main-color: var(--total-green);
	--b2c-main-color-gradient: var(--total-green-gradient);
	--b2c-box-color-gradient: var(--total-green-gradient);
	--b2c-title-color-gradient: var(--total-green-gradient);
	--b2c-timeline-icon-color: var(--total-light-blue);
	--b2c-timeline-title-color-gradient: var(--total-light-blue-gradient);
	--theme-color: var(--total-green);
	--theme-color-gradient: var(--total-red-gradient);
	--theme-color-box: var(--total-red-gradient);
	--theme-color-title: var(--total-red-gradient);
	--theme-icon-timeline: var(--total-light-blue);
	--theme-icon-timeline-title: var(--total-light-blue-gradient);
	--theme-button-color-primary: var(--total-light-blue);
	--theme-button-color-success: var(--total-green);
	--theme-button-color-warning: var(--total-red);
}

.header-total {
	background-color: white;
	box-shadow: none;
	border: 0;
	border-bottom: 3px solid;
	border-image-slice: 1;
	border-image-source: linear-gradient(90deg, var(--total-rainbow));
	height: 60px;
}

.header-title {
	font-family: 'Netto Offc W02 Bold', sans-serif;
	color: var(--total-red);
	font-weight: bold;
}

.mainContainerTitleBundles {
	background: linear-gradient(90deg, var(--total-red-gradient));
}

.mainContainerTitleProducts {
	background: linear-gradient(90deg, var(--total-blue-gradient));
}

.mainContainerTitlePromotions {
	background: linear-gradient(90deg, var(--total-green-gradient));
}

.mainContainerTitleGeeps {
	background: linear-gradient(90deg, var(--total-light-blue-gradient));
}

.mainContainerTitleMonitoring {
	background: linear-gradient(90deg, var(--total-orange-gradient));
}

.mainContainerTitleExport {
	background: linear-gradient(90deg, var(--total-light-green-gradient));
}

.mainContainerTitleImport {
	background: linear-gradient(90deg, var(--total-turquoise-gradient));
}

.mainContainerTitleCaches {
	background: linear-gradient(90deg, var(--total-red-gradient));
}

.mainContainerTitleBundles,
.mainContainerTitleProducts,
.mainContainerTitlePromotions,
.mainContainerTitleGeeps,
.mainContainerTitleMonitoring,
.mainContainerTitleExport,
.mainContainerTitleImport,
.mainContainerTitleCaches {
	width: fit-content;
	margin-top: 2px;
	font-size: 1.1rem;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	line-height: 2.1rem !important;
	font-family: Roboto, sans-serif !important;
}

.clr-vertical-nav .nav-link.active .nav-icon.bundles-nav {
	fill: var(--total-red);
}

.clr-vertical-nav .nav-link.active .nav-icon.products-nav {
	fill: var(--total-blue);
}

.clr-vertical-nav .nav-link.active .nav-icon.promotions-nav {
	fill: var(--total-green);
}

.clr-vertical-nav .nav-link.active .nav-icon.geeps-nav {
	fill: var(--total-light-blue);
}

.clr-vertical-nav .nav-link.active .nav-icon.sales-context-nav {
	fill: mediumpurple;
}

.clr-vertical-nav .nav-link.active .nav-icon.monitoring-nav {
	fill: var(--total-orange);
}

.clr-vertical-nav .nav-link.active .nav-icon.export-nav {
	fill: var(--total-light-green);
}

.clr-vertical-nav .nav-link.active .nav-icon.import-nav {
	fill: var(--total-turquoise);
}

.clr-vertical-nav .nav-link.active .nav-icon.caches-nav {
	fill: var(--total-gradient-orange-red);
}

.clr-vertical-nav .nav-link.active.bundles-nav {
	border-right: 4px solid var(--total-red);
}

.clr-vertical-nav .nav-link.active.products-nav {
	border-right: 4px solid var(--total-blue);
}

.clr-vertical-nav .nav-link.active.promotions-nav {
	border-right: 4px solid var(--total-green);
}

.clr-vertical-nav .nav-link.active.geeps-nav {
	border-right: 4px solid var(--total-light-blue);
}

.clr-vertical-nav .nav-link.active.sales-context-nav {
	border-right: 4px solid mediumpurple;
}

.clr-vertical-nav .nav-link.active.monitoring-nav {
	border-right: 4px solid var(--total-orange);
}

.clr-vertical-nav .nav-link.active.export-nav {
	border-right: 4px solid var(--total-light-green);
}

.clr-vertical-nav .nav-link.active.import-nav {
	border-right: 4px solid var(--total-turquoise);
}

.clr-vertical-nav .nav-link.active.caches-nav {
	border-right: 4px solid var(--total-gradient-orange-red);
}

.btn.btn-success {
	border-color: var(--total-green) !important;
	background-color: var(--total-green) !important;
}

.btn.btn-success:hover {
	border-color: var(--total-light-green) !important;
	background-color: var(--total-green) !important;
}

.btn.btn-primary {
	border-color: var(--total-light-blue) !important;
	background-color: var(--total-light-blue) !important;
}

.btn.btn-primary:hover {
	border-color: var(--total-turquoise) !important;
	background-color: var(--total-light-blue) !important;
}

.btn.btn-primary-outline {
	background-color: transparent !important;
	border-color: var(--total-light-blue) !important;
}

.btn.btn-primary-outline:hover {
	background-color: #b3d7ff !important;
	border-color: var(--total-blue) !important;
}

.btn.btn-warning {
	border-color: var(--total-red) !important;
	background-color: var(--total-red) !important;
}

.btn.btn-warning:hover {
	border-color: var(--total-orange) !important;
	background-color: var(--total-red) !important;
}

.btn.btn-warning-outline {
	color: var(--total-red) !important;
	border-color: var(--total-red) !important;
}

.btn.btn-warning-outline:hover {
	background-color: var(--total-outline-btn-red-hover);
	color: var(--total-red) !important;
	border-color: var(--total-red) !important;
}

.btn.btn-success-outline {
	border-color: var(--total-green) !important;
	color: var(--total-green) !important;
}

.btn.btn-success-outline:hover {
	border-color: var(--total-green) !important;
	background-color: var(--total-outline-btn-green-hover) !important;
}

.btn-outline-success-icon {
	color: var(--total-green);
}

.left-menu {
	box-shadow: inset 0 0 3px lightgray;
}

@font-face {
	font-family: 'Netto Offc W02 Bold';
	src:
		url('./assets/fonts/1379068/9708408d-8f84-4042-88a1-6d6c86a4fd79.woff2') format('woff2'),
		url('./assets/fonts/1379068/845ccfeb-47f7-4947-9323-c2df73ac7649.woff') format('woff');
}

button {
	outline: none !important;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	margin: 0;
	font-family: Roboto, sans-serif;
}

h2 {
	margin-left: 13px;
}

.multiselect-dropdown,
.dropdown-btn,
.selected-item {
	background-color: var(--total-light-blue) !important;
	color: white !important;
}

.multiselect-dropdown,
.dropdown-btn,
.selected-item:hover {
	background-color: white !important;
	border-color: grey !important;
	box-shadow: none;
}

.multiselect-dropdown,
.dropdown-btn,
.selected-item a {
	border-color: darkslategrey !important;
	padding: 0 1px !important;
}

.multiselect-dropdown .dropdown-btn {
	padding: 0 !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-down {
	display: inline-block;
	top: 3px;
	width: 0;
	height: 0;
	border-top: 7px solid cornflowerblue !important;
	border-left: 7px solid transparent !important;
	border-right: 7px solid transparent !important;
}

mat-sidenav-container,
mat-sidenav-content,
mat-sidenav {
	height: 100%;
}

mat-sidenav {
	width: 250px;
}

mat-toolbar a {
	color: white;
	text-decoration: none;
}

mat-toolbar a:hover {
	color: lightgray;
}

.container {
	max-width: 1400px;
	margin: 25px auto;
}

table {
	width: 100%;
}

.mt-1 {
	margin-top: 1em !important;
}

.mt-2 {
	margin-top: 2em !important;
}

.mt-3 {
	margin-top: 3em !important;
}

.mb-1 {
	margin-bottom: 1em !important;
}

.mb-2 {
	margin-bottom: 2em !important;
}

.mb-3 {
	margin-bottom: 3em !important;
}

.mr-1 {
	margin-right: 1em !important;
}

.mr-2 {
	margin-right: 2em !important;
}

.mr-3 {
	margin-right: 3em !important;
}

.p-1 {
	padding: 1em !important;
}

.p-2 {
	padding: 2em !important;
}

.p-3 {
	padding: 3em !important;
}

.table tr.row {
	cursor: pointer;
}

.table tr.row:hover {
	background: rgb(27 108 52 / 10%);
}

.block-center {
	margin: auto;
}

.text-center {
	text-align: center;
}

.snackbar-success {
	background-color: #1b6c34 !important;
	color: white !important;
}

.snackbar-error {
	background-color: #c90000 !important;
	color: white !important;
}

/**
 BUTTON STYLE
 */

.btn-lampiris {
	border-color: #268d1b !important;
	background-color: #1b6c34 !important;
	color: #fff;
}

.btn-lampiris:hover {
	border-color: #1b6c34 !important;
	background-color: #268d1b !important;
	color: #fff;
}

.btn-lampiris-border {
	border-color: #1b6c34 !important;
	color: #1b6c34 !important;
}

.btn-lampiris-border:hover {
	border-color: #268d1b !important;
	color: #268d1b !important;
	background-color: #f4fef3;
}

.btn-lampiris-list {
	border-color: #268d1b;
	background-color: transparent;
	color: #268d1b;
}

.btn-lampiris-list:hover {
	border-color: #1b6c34;
	background-color: #f4fef3;
	color: #1b6c34;
}

.mat-drawer-backdrop .mat-drawer-shown {
	background-color: rgb(0 0 0 / 40%);
}

nav.breadcrumb {
	margin: 10px 0;
}

nav.breadcrumb > a {
	font-size: 18px;
	color: var(--total-red);
}

nav.breadcrumb > a::before {
	content: '/';
	color: var(--total-red);
	vertical-align: top;
	display: inline-block;
	font-weight: normal;
	font-style: normal;
	font-size: 25px;
	margin: 0 10px 0 8px;
	-webkit-font-smoothing: antialiased;
}

nav.breadcrumb > a:first-child::before {
	display: none;
}

nav.breadcrumb > a:last-child {
	color: var(--total-red);
}

.d-flex {
	display: flex;
}

.justify-content-between {
	justify-content: space-between;
}

.cursor-pointer {
	cursor: pointer;
}
